<template>
  <meta name="viewport" content="initial-scale=1,maximum-scale=3,minimum-scale=1" />
<div style="background-color: #f0f0f0;">
  <section class="homepage" id="home" >
      <div class="content">
        <div class="text">
          <h1>{{Page1.title[Language]}}</h1>
          <p >{{Page1.content[Language]}}</p>
        </div>
        <a href="/About">{{details[Language] }} >></a>
      </div>
    </section>

    <!-- <section class="Aboards" id="Aboards"> -->
      
      <!-- <table class="AboardsTable">
        <tr>
         <td colspan="3"> <h2>{{Page2.title[Language]}}</h2> </td> 
        </tr>
      
      <tr v-for="item in Page2.content">
        <td style="text-align: center;color:rgb(164, 162, 162);">{{ item.time }}</td>
        <td style="text-align: left;">{{ item.name[Language] }}</td>
        <td style="text-align: center;color:rgb(164, 162, 162);">more >></td>
      </tr>
    </table> -->
  <!-- </section> -->

  <!-- <section class="Articles" id="Articles"> -->
    <div class="showPages" >
        <div class="imageRight">
          <img class="imageShow" :src="Page2.img" alt="图片描述">
        </div>
        <div class="textLeft">
          <h1>{{Page2.title[Language]}}</h1>
          <br/>
          <a href="/Information/JoinUs" style="color:rgb(28, 140, 144) ;">{{Page2.content[Language]}}>></a>
        </div>
        
      </div>
    <!-- </section> -->
  <!-- <section></section> -->
  
  <section class="homepage"  :style="{ backgroundImage: `url(${Page3.img})` }" >
      <div class="content">
        <div class="text">
          <h1>{{Page3.title[Language]}}</h1>
          <p>{{Page3.content[Language]}}</p>
        </div>
        <a href="/service">{{ details[Language] }} >></a>
      </div>
    </section>


    <!-- <section class="homepage"  :style="{ backgroundImage: `url(${Page4.img})` }" id="about1" >
      <div class="content">
        <div class="text">
          <h1>{{Page4.title[Language]}}</h1>
          <p>{{Page4.content[Language]}}</p>
        </div>
        <a href="/About">{{ Page4.title[Language] }} >></a>
      </div>
    </section> -->

   
    <section class="homepage"  :style="{ backgroundImage: `url(${Page6.img})` }" >
      <div class="content">
        <div class="text">
          <h1>{{Page6.title[Language]}}</h1>
          <p>{{Page6.content[Language]}}</p>
        </div>
        <a href="/Connection">{{ details[Language] }} >></a>
      </div>
    </section>
    <section class="homepage"  :style="{ backgroundImage: `url(${Page5.img})` }" >
      <div class="content">
        <div class="text">
          <h1>{{Page5.title[Language]}}</h1>
          <p>{{Page5.content[Language]}}</p>
        </div>
        <a href="/Report">{{ details[Language] }} >></a>
      </div>
    </section>

  </div>
</template>

<script>
import "@/assets/css/style.css";
import axios from 'axios';
export default {
  watch:{
      '$store.state.Language':{
        handler(newVal){
          this.Language=newVal;
        },
        deep: true
      }
    },
    created(){
        // axios.get('https://www.policy-nexus.com/api/').then(res=>{
        //     this.Page2.content[0]= res.data.announcement_content;
        //     this.Page2.img='https://www.policy-nexus.com/'+res.data.file_url;
      
        // }).catch(error => {
        //   console.error('Failed :', error);
        // });

    },
  data() {
    let self = this;
    return {
      Language:this.$store.state.Language,
      details:["了解更多","more"],
      Page1:{title:["关于汇策","About Policy Nexus"],content:['长期主义 ·  乐观主义  ·  一线视角  ·  简单高效','Long-termism  ·  Optimism  ·  Front-line Perspective  ·  Simplicity and Efficiency'],
      img:require('@/assets/images/first/1.jpg')},
      Page2:{title:["加入我们","Join Us"],content:['汇策咨询全新机遇：加入我们，共塑未来！',"A new opportunity for Huice Consulting: Join us and build the future together."],img:require('@/assets/images/logo.png')},
      Page3:{title:["我们的服务","What We Do"],content:['为您的企业在异国他乡保驾护航','To navigate your business over the sea'],img:require('@/assets/images/first/2.png')},
      Page4:{title:["了解汇策","About"],content:['测试','EN'],img:require('@/assets/images/1.jpg')},
      Page5:{title:["政策洞察","Insights"],content:['聚焦关键议题，提供专业意见，助力客户决策','To read our latest, in-depth insights of the pressing issues and subjects the clientele interested'],img:require('@/assets/images/first/3.png')},
      Page6:{title:["人际关系","Our Connections"],content:['专业团队，助您的事业一帆风顺','Professional service from beginning to the end'],img:require('@/assets/images/first/3.jpg')}

     
    };
  },
 
};
</script>

<style>
#home{
  background-image: url('@/assets/images/first/1.jpg');
}
@media screen and (max-width: 860px) {
  #home{
    background-image: url('@/assets/images/first/1-min.jpg');
  }
}
.AboardsTable{
  background-color: #fff;
  box-shadow: 10px 10px 5px #888888;
  width:80%;
  border-spacing: 20px;
  border: 1px solid rgba(197, 203, 208, 0.773);
}
td, th {
  border: none; /* 隐藏单元格内边框 */
  padding: 1px; /* 可选，设置单元格内边距 */
}

.showPages {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 50%;
  background-color: #fff; /* 设置内容区域的背景颜色 */
  /* border-radius: 10px; 设置内容区域的圆角 */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 设置内容区域的阴影效果 */
  padding: 20px;
  color:rgb(28, 140, 144)
}

.textLeft {
  flex: 1;
}

.imageRight {
  height: 50%;
  flex: 1;
  text-align: center;

}

.imageLeft {
  float:left; 
  margin: 5px;
  height: 50%;
  flex: 1;
  text-align: center;
}

/* 设置图片的最大宽度 */
.imageShow {
  max-width: 50%;
  /* min-height: 50%; */
  /* height: auto; */
}




</style>
