<template>
<div id="contact-bar">
        <div id="contact-info">
            <p>Contact Info： <br/>Info@policynexus.co <br/>https://www.linkedin.com/company/policy-nexus/<br/>Mobile number: +1 2028807621<br/></p>
            <p>Location： <br/>100 University Ave.</p>
        </div>
        <div id="links-and-copyright">
          <p><img :src="photo" alt="Wechat Photo" id="wechat-photo"></p>
            <router-link tag="a" target="_blank" to="/" >{{Home[Language]}}</router-link>
            <router-link tag="a" target="_blank" to="/About" >{{About[Language]}}</router-link>
            <router-link tag="a" target="_blank" to="/Information/JoinUs" >{{JoinUs[Language]}}</router-link>
            
            <p>&copy; 2023 Policy Nexus</p>
            <router-link tag="a" target="_blank" to="/Information/Disclaimer" >{{Disclaimer[Language]}}</router-link>
            
            
        </div>
</div>
  </template>
  
  <script>
  import "@/assets/css/style.css";
  export default {
    watch:{
        '$store.state.Language':{
          handler(newVal){
            this.Language=newVal;
          },
          deep: true
        }
      },
    data() {
      let self = this;
      return {
        Language:this.$store.state.Language,
        Home:["首页","Home"],
        About:["关于","About"],
        Disclaimer:["免责声明","Disclaimer"],
        JoinUs:["加入我们","Join Us"],

        photo: require('@/assets/images/logo2.jpg'),
        
  
       
      };
    },
   
  };
  </script>
  
  <style>
#wechat-photo{
  width: 100px;
  height: 100px;
  object-fit: cover;
}
#contact-bar {
    background-color:rgb(28, 140, 144);
    /* height:20%; */
    color: #fff;
    padding: 5vh 20vh 10vh 20vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#contact-info {
    flex: 1;
    text-align: left;
}
#contact-info p {
    margin: 0;
}
#links-and-copyright {
    text-align: right;
}
#links-and-copyright p{
    margin-right: 35px;
}
#links-and-copyright a {
    color: #fff;
    text-decoration: none;
    margin-right: 20px;
    font-size: large;
}
#links-and-copyright a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 860px) {

#contact-bar {
    background-color: rgb(28, 140, 144);
    /* height:20%; */
    color: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
}
@media screen and (max-width: 460px) {

#contact-bar {
    flex-direction: column; 
    
}

#links-and-copyright p{
    margin-right: 0px;
}
#links-and-copyright a {
    color: #fff;
    text-decoration: none;
    margin-left: 10px;
    margin-right: 0px;
    font-size: large;
}
}
  </style>
  