import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component:HomeView,
    meta:{
      title: '汇策'
    }
  },
  {
    path:'/About',
    name:'About',
    component:()=>import('../views/About.vue'),
    meta:{
      title: 'About'
    }
  },
  {
    path:'/Information/Disclaimer',
    name:'Disclaimer',
    component:() =>import('../views/Information/Disclaimer.vue'),
    meta:{
      title: 'Disclaimer'
    }
  },
  {
    path:'/Information/JoinUs',
    name:'JoinUs',
    component:() =>import('../views/Information/JoinUs.vue'),
    meta:{
      title: 'JoinUs'
    }
  },

  {
    path:'/news1',
    name:'news1',
    component:()=>import('../views/News/news1.vue'),
    meta:{
      title: 'Policy Nexus Events 2023'
    }
  },
  {
    path:'/service',
    name:'servive',
    component:() =>import('../views/Service/Service.vue'),
    meta:{
      title: 'Service&Area'
    }
  },
  {
    path:'/service/GoverC',
    name:'GoverC',
    component:()=>import('../views/Service/GovernC.vue'),
    meta:{
      title: 'GoverC'
    }
  },
  {
    path:'/service/Commu',
    name:'Commu',
    component:() =>import('../views/Service/Commu.vue'),
    meta:{
      title: 'Communication'
    }
  },
  {
    path:'/service/Consult',
    name:'Consult',
    component:() =>import('../views/Service/Consult.vue'),
    meta:{
      title: 'Consult'
    }
  },
  {
    path:'/service/Edu',
    name:'Edu',
    component:() =>import('../views/Service/Edu.vue'),
    meta:{
      title: 'Edu'
    }
  },
  {
    path:'/service/Solu',
    name:'Solu',
    component:() =>import('../views/Service/Solu.vue'),
    meta:{
      title: 'Solution'
    }
  },


  {
    path:'/Connection',
    name:'Connection',
    component:()=>import('../views/Connection/Connection.vue'),
    meta:{
      title: 'Connection'
    }
  },
  {
    path:'/Connection/LTY',
    name:'LTY',
    component:()=>import('../views/Connection/LTY.vue'),
    meta:{
      title: 'Thomas Liu'
    }
  },
  {
    path:'/Report',
    name:'Report',
    component:()=>import('../views/Report/Report.vue'),
    meta:{
      title: 'Report'
    }
  },
  {
    path:'/Report/:title/:id',
    // path:'/Report/:id',
    name:'ReportDetail',
    component:()=>import('../views/Report/ReportDetail.vue'),
    meta:{
      title: 'ReportDetail',
      requiresAuth: true
    }
  },
  {
    path: '/News',
    name: 'News',
    component:()=>import('../views/News/news.vue'),
    meta:{
      title: 'News'
    }
  },
  {
    path:'/login',
    name:'login',
    component:()=>import('../views/Login.vue'),
    meta:{
      title: 'login',
    }
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
// 顶部加载
router.afterEach(() => {
  const pageContainer = document.querySelector('.page');
  if (pageContainer) {
    pageContainer.scrollTop = 0;
  }
});


export default router
